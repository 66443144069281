import React from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import { MAIN_PAGE_IMAGE } from 'constants/images';

interface OuterImageDivProps {
  url?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  inView?: boolean; // used below but eslint doesn't seem to notice
  children?: React.ReactNode;
}

const OuterImageDiv = styled.div`
  width: 100vw;
  z-index: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  min-height: 620px; // the min height that the logo and details look good and don't overlap the navbar

  background-color: #293539;
`;

export const ImageBackgroundDiv = styled.div<OuterImageDivProps>`
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1; // so that everything sits on top
  min-height: 620px; // the min height that the logo and details look good and don't overlap the navbar

  ${({ inView }) => `opacity: ${inView ? 1 : 0};`}
  background-image: url(${(props) => props.url || MAIN_PAGE_IMAGE});
  transition: opacity 1000ms linear;
  filter: blur(5px);
  background-size: cover;
  background-position: center;
`;

const MainImageDiv = ({ url, children }: OuterImageDivProps) => {
  const { inView, ref } = useInView({ triggerOnce: true });

  return (
    <OuterImageDiv>
      <ImageBackgroundDiv url={url} inView={inView} ref={ref} />
      {children}
    </OuterImageDiv>
  );
};

export default MainImageDiv;
