import React, { useRef } from 'react';
import styled from 'styled-components';
import Body from 'components/common/body';
import SimpleButton from 'components/common/button/simple';
import Heading from 'components/common/heading';
import InfoBox from 'components/common/infoBox';
import Link from 'components/common/link';
import LineBreak from 'components/common/sectionBreak/lineBreak';
import Container from 'components/container';
import IdealistFeedItem from 'components/idealist/idealistFeedItem';
import DownArrow from 'components/idealist/landingPage/downArrow';
import PublicLayout from 'components/layouts/Public';
import Logo from 'components/logo';
import MainImageDiv from 'components/pages/homePage/mainImage';
import { IS_PRODUCTION } from 'constants/env';
import { IDEALIST_PLACEHOLDER_IMAGE } from 'constants/images';
import { FEED_URL } from 'constants/urls';
import { Idealist } from 'containers/idealist';
import JoinTheWaitlist from 'containers/joinTheWailistForm';
import { TopUsersRow } from 'containers/topUsers';
import { makeReferralLink } from 'graphql/constants/referralLink';
import { useAuthenticatedUser } from 'utils/auth';
import { theme } from 'utils/theme';
import { useStaticRouter } from 'utils/useStaticRouter';

const FullWidthDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HomePage = () => {
  const router = useStaticRouter();
  const { referralId } = router.query;
  const user = useAuthenticatedUser();
  const firstExampleRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  return (
    <PublicLayout
      referralLink={referralId ? makeReferralLink(referralId as string) : undefined}
      displayJoinButtonInsteadOfMenu={!!referralId}
    >
      <MainImageDiv>
        <Container centerContent>
          <Logo height={250} width={250} light />
          <Heading centered light level={100} margin="40px 0" alternates>
            IdeaList
          </Heading>
          <Heading centered light level={200} margin="0 0 40px 0">
            The social network for people that explore.
          </Heading>
          <InfoBox info="Currently in Beta. Feedback is much appreciated 🙂" />
          <DownArrow
            onClick={() => firstExampleRef.current?.scrollIntoView({ behavior: 'smooth' })}
          />
        </Container>
      </MainImageDiv>

      <Container margin="0px 0 30px 0">
        {user && (
          <FullWidthDiv>
            <Link href={FEED_URL}>
              <SimpleButton backgroundColor={theme.customColors.success} width="300px">
                <Heading level={400}> Go to the feed!</Heading>
              </SimpleButton>
            </Link>
          </FullWidthDiv>
        )}
        <Heading margin="10px 10px 0 10px" level={300}>
          Join a community of frequent travelers, see what they explore and follow their steps. Take
          a look below to see how it works!
        </Heading>
      </Container>

      <div ref={firstExampleRef} />
      <Container
        withFadeIn
        margin="20px 0 20px 0"
        padding="20px 0"
        backgroundColor={theme.customColors.lightGraySemiTransparent}
      >
        <Idealist
          idealist={{
            id: 'demoIdealistId0',
            createdAt: 'Jan 12, 2023',
            isDraft: false,
            title: '💬 Remember when your friend asked for what you did on that trip?',
            location: { id: 'location0', name: 'What city were you in?' },
            description:
              'Write a short paragraph about how that was, and share information on location, and how long it took to complete. Then, list the steps you took.',
            creator: {
              id: 'creatorId',
              name: 'you', // so on the list it says 'Here's what you did:'
              username: 'mihaianton',
              image:
                'https://lh3.googleusercontent.com/a/AEdFTp5GHkWbhuPjhtyA6EOP-8IQwN-Nv1z_p3Lrj_iNoqw=s160-c',
            },
            images: [
              {
                id: 'img0',
                src: IDEALIST_PLACEHOLDER_IMAGE,
              },
              {
                id: 'img1',
                src: 'https://images.unsplash.com/photo-1469854523086-cc02fe5d8800?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8dHJhdmVsfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=1296&q=60',
              },
              {
                id: 'img2',
                src: 'https://images.unsplash.com/photo-1488085061387-422e29b40080?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fHRyYXZlbHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=1296&q=60',
              },
              {
                id: 'img3',
                src: 'https://images.unsplash.com/photo-1503220317375-aaad61436b1b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fHRyYXZlbHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=1296&q=60',
              },
              {
                id: 'img4',
                src: 'https://images.unsplash.com/photo-1452421822248-d4c2b47f0c81?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzN8fHRyYXZlbHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=1296&q=60',
              },
            ],
          }}
          actionsDoNothing
          idealistStats={{ likeCount: 20, likedByUser: true, completedByUser: false }}
          hasTitle
          hasDescription
          user={null}
          idealistItems={[
            { id: 'item0', description: '🚃 This can be what train you took.' },
            { id: 'item1', description: '🏙️ Or what street you walked on.' },
            { id: 'item2', description: '🍔 Or that fancy restaurant you had dinner at.' },
            { id: 'item3', description: '⛰️ The hike you went on.' },
            { id: 'item4', description: '🎢 Or the local experience you tried.' },
          ]}
        />
      </Container>

      <Container withFadeIn margin="20px 0 20px 0" padding="20px 5px">
        <Heading margin="0 0 20px 0" level={200}>
          See what your friends post.
        </Heading>
        <IdealistFeedItem
          actionsDoNothing
          hideActions
          hideCompletionStats
          skipLink
          skipFadeIn
          key="demoIdealistId1"
          idealistStats={{ likeCount: 20, likedByUser: true, completedByUser: false }}
          idealist={{
            id: 'demoIdealistId1',
            createdAt: '10 Feb 2023',
            title: 'A week in Estes Park, Colorado 🏔️',
            location: { id: 'location2', name: 'Estes Park, Colorado, United States' },
            image:
              'https://images.unsplash.com/photo-1468711007652-03aab17ae4d2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
            description:
              'Went on a business trip for one week in Estes Park, Colorado. Great mountains, American roads and high altitude hikes.',
            isDraft: false,
            creator: {
              id: 'demoCreatorId1',
              username: 'mihaianton',
              image:
                'https://lh3.googleusercontent.com/a/AEdFTp5GHkWbhuPjhtyA6EOP-8IQwN-Nv1z_p3Lrj_iNoqw=s160-c',
            },
            completedBy: [],
          }}
        />
        <IdealistFeedItem
          actionsDoNothing
          hideActions
          hideCompletionStats
          skipLink
          skipFadeIn
          key="demoIdealistId2"
          idealistStats={{ likeCount: 20, likedByUser: true, completedByUser: false }}
          idealist={{
            id: 'demoIdealistId2',
            createdAt: '20 Dec 2022',
            title: 'A summer day in NYC 🌇',
            location: { id: 'location2', name: 'New York City, United States' },
            description:
              "New York is an amazing place. There are many things to do, but you can only scratch the surface in one day. Here's what I did on a full weekend day in Manhattan and Brooklyn.",
            image:
              'https://images.unsplash.com/photo-1485871981521-5b1fd3805eee?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
            isDraft: false,
            creator: {
              id: 'demoCreatorId2',
              username: 'mihaianton',
              image:
                'https://lh3.googleusercontent.com/a/AEdFTp5GHkWbhuPjhtyA6EOP-8IQwN-Nv1z_p3Lrj_iNoqw=s160-c',
            },
            completedBy: [],
          }}
        />
      </Container>

      <Container
        withFadeIn
        margin="20px 0 20px 0"
        padding="40px 5px"
        backgroundColor={theme.customColors.lightGraySemiTransparent}
      >
        <Heading margin="0 0 20px 0" level={200}>
          Plan and complete lists you like.
        </Heading>
        <IdealistFeedItem
          actionsDoNothing
          skipLink
          skipFadeIn
          hideCompletionStats
          key="demoIdealistId3"
          idealistStats={{ likeCount: 20, likedByUser: true, completedByUser: false }}
          idealist={{
            id: 'demoIdealistId3',
            createdAt: '10 Feb 2023',
            title: 'Exploring Singapore 🌏',
            location: { id: 'location3', name: 'Singapore' },
            image:
              'https://images.unsplash.com/photo-1525625293386-3f8f99389edd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2104&q=80',
            description:
              "Even if it's a small place, both the city and the outskirts are amazing. Make sure you go to Sentosa and check out the Marina Bay Sands building.",
            isDraft: false,
            creator: {
              id: 'demoCreatorId1',
              username: 'mihaianton',
              image:
                'https://lh3.googleusercontent.com/a/AEdFTp5GHkWbhuPjhtyA6EOP-8IQwN-Nv1z_p3Lrj_iNoqw=s160-c',
            },
            completedBy: [],
          }}
        />

        <Heading margin="0 0 20px 0" level={200}>
          See what your friends explore.
        </Heading>
        <IdealistFeedItem
          actionsDoNothing
          skipLink
          skipFadeIn
          hideActions
          key="demoIdealistId4"
          idealistStats={{ likeCount: 20, likedByUser: true, completedByUser: false }}
          idealist={{
            id: 'demoIdealistId4',
            createdAt: '10 Feb 2023',
            title: '10 days in Dubai ',
            location: { id: 'location4', name: 'Dubai, UAE' },
            description:
              'Dubai is a city located in the United Arab Emirates and is known for its luxury shopping, ultramodern architecture, and thriving tourism industry. It is a global hub for business and leisure and is home to numerous skyscrapers, including the iconic Burj Khalifa, the tallest building in the world.',
            image:
              'https://images.unsplash.com/flagged/photo-1559717865-a99cac1c95d8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2071&q=80',
            isDraft: false,
            creator: {
              id: 'demoCreatorId1',
              username: 'mihaianton',
              image:
                'https://lh3.googleusercontent.com/a/AEdFTp5GHkWbhuPjhtyA6EOP-8IQwN-Nv1z_p3Lrj_iNoqw=s160-c',
            },
            completedBy: [
              {
                id: 'completedBy0',
                name: 'Travis Howard',
                image:
                  'https://static.vecteezy.com/system/resources/previews/008/172/400/original/woman-face-with-flower-in-her-hair-in-doodle-style-colorful-avatar-of-smiling-girl-vector.jpg',
              },
              {
                id: 'completedBy1',
                name: 'Remy Sharp',
                image:
                  'https://cdn.dribbble.com/users/5291726/screenshots/15793181/media/472e3f2bffef95b58ff5a0c01fca2385.png?compress=1&resize=400x300&vertical=top',
              },
              {
                id: 'completedBy2',
                name: 'Agnes Walker',
                image:
                  'https://cdn.dribbble.com/userupload/3123396/file/original-9662aaed482b555fa6ff30897654fe6f.png?compress=1&resize=400x300&vertical=top',
              },
              ...Array.from(Array(61).keys()).map((key) => ({
                id: `completedBy${3 + key}`,
                name: 'Cindy Baker',
                image: '',
              })),
            ],
          }}
        />

        <Heading margin="0 0 20px 0" level={200}>
          Earn tokens and compete in the weekly challenge.
        </Heading>
        <TopUsersRow
          users={[
            {
              id: 'TopUsersRowUser9',
              username: 'travis',
              image:
                'https://static.vecteezy.com/system/resources/previews/008/172/400/original/woman-face-with-flower-in-her-hair-in-doodle-style-colorful-avatar-of-smiling-girl-vector.jpg',
              score: 64,
            },
            {
              id: 'TopUsersRowUser1',
              username: 'mihaianton',
              image:
                'https://lh3.googleusercontent.com/a/AEdFTp5GHkWbhuPjhtyA6EOP-8IQwN-Nv1z_p3Lrj_iNoqw=s160-c',
              score: 32,
            },
            {
              id: 'TopUsersRowUser2',
              username: 'agnes',
              image:
                'https://cdn.dribbble.com/userupload/3123396/file/original-9662aaed482b555fa6ff30897654fe6f.png?compress=1&resize=400x300&vertical=top',
              score: 28,
            },
            {
              id: 'TopUsersRowUser3',
              username: 'remy',
              image:
                'https://cdn.dribbble.com/users/5291726/screenshots/15793181/media/472e3f2bffef95b58ff5a0c01fca2385.png?compress=1&resize=400x300&vertical=top',
              score: 12,
            },
          ]}
        />
      </Container>

      <Container withFadeIn>
        <JoinTheWaitlist />
      </Container>

      <Container>
        {!IS_PRODUCTION && (
          <>
            <LineBreak />
            <Heading level={300}>For debugging. Not visible in production.</Heading>
            <Heading level={100}>Heading level 100</Heading>
            <Heading level={200}>Heading level 200</Heading>
            <Heading level={300}>Heading level 300</Heading>
            <Heading level={400}>Heading level 400</Heading>
            <Body>Body, normal</Body>
            <Body size="small">Body, small</Body>
            <Body size="xsmall">Body, xsmall</Body>
          </>
        )}
      </Container>
    </PublicLayout>
  );
};

export default HomePage;
