import gql from 'graphql-tag';
import { USER_CORE_FRAGMENT } from 'utils/fragments.query';

export const GET_TOP_USERS = gql`
  query GetTopUsers($take: Int) {
    getTopUsers(take: $take) {
      ...UserCore
      score
    }
  }

  ${USER_CORE_FRAGMENT}
`;
