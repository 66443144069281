import React from 'react';
import styled from 'styled-components';
import { theme } from 'utils/theme';

interface LineBreakProps {
  color?: string;
}

const LineBreakDiv = styled.div<Pick<LineBreakProps, 'color'>>`
  width: 100%;
  height: 2px;
  margin: 10px 0;
  border-radius: 5px;
  background-color: ${(props) => props.color || theme.colors.gray300};
`;

const LineBreak = ({ color }: LineBreakProps) => {
  return <LineBreakDiv color={color} />;
};

export default LineBreak;
