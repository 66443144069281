import { useQuery } from '@apollo/client';
import React from 'react';
import { Award } from 'react-feather';
import styled from 'styled-components';
import Body from 'components/common/body';
import InfoBox from 'components/common/infoBox';
import Link from 'components/common/link';
import ProfileImage from 'components/idealist/profileImage';
import { PROFILE_PICTURE_TOP_USERS_SIZE } from 'constants/images';
import { USER_PROFILE_URL } from 'constants/urls';
import { GetTopUsersQuery, GetTopUsersQueryVariables, User } from 'graphql/generated';
import { theme } from 'utils/theme';
import { GET_TOP_USERS } from './topUsers.query';

const OuterDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

const InnerDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100%;
  overflow: scroll;
  overflow-y: hidden;
  padding-bottom: 10px;
`;

const TopUserDiv = styled.div`
  display: flex;
  margin-right: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AwardScoreDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4px;
  min-width: 85px;
`;

interface TopUsersRowProps {
  users: Pick<User, 'id' | 'image' | 'username' | 'score'>[];
}

export const TopUsersRow = ({ users }: TopUsersRowProps) => (
  <OuterDiv>
    <InnerDiv>
      {users.map((user, idx) => (
        <Link href={USER_PROFILE_URL(user.username || '')} key={idx}>
          <TopUserDiv>
            <ProfileImage
              src={user.image}
              username={user.username}
              height={PROFILE_PICTURE_TOP_USERS_SIZE}
              width={PROFILE_PICTURE_TOP_USERS_SIZE}
            />
            <Body size="xsmall" color="black" margin="10px 0 0 0">
              @{user.username}
            </Body>
            <AwardScoreDiv>
              <Award size={16} color={theme.customColors.getRankColor(idx)} />
              <Body size="xsmall" color="black" margin="0">
                Score: {user.score}
              </Body>
            </AwardScoreDiv>
          </TopUserDiv>
        </Link>
      ))}
    </InnerDiv>
    <InfoBox
      fullWidth
      info="Earn tokens by posting great content that other users engage with. The challenge restarts weekly."
    />
  </OuterDiv>
);

const TopUsers = () => {
  const { data: getTopUsers, loading: getTopUsersLoading } = useQuery<
    GetTopUsersQuery,
    GetTopUsersQueryVariables
  >(GET_TOP_USERS);

  if (getTopUsersLoading || !getTopUsers?.getTopUsers) {
    return null;
  }

  return <TopUsersRow users={getTopUsers.getTopUsers} />;
};

export default TopUsers;
