import { ArrowDown } from 'react-feather';
import styled from 'styled-components';
import { theme } from 'utils/theme';

const Div = styled.div`
  margin: 20px 0 0 0;
`;

interface DownArrowProps {
  onClick?: () => void;
}

const DownArrow = ({ onClick }: DownArrowProps) => (
  <Div onClick={onClick}>
    <ArrowDown size={64} color={theme.colors.white} />
  </Div>
);

export default DownArrow;
